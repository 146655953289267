var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-table",
          {
            ref: "table",
            attrs: {
              title: "보호구 목록",
              tableId: "grid",
              columns: _vm.grid.columns,
              data: _vm.grid.data,
              isExcelDown: false,
              isExpan: false,
              isFullScreen: false,
              columnSetting: false,
              gridHeight: "500px",
              rowKey: "requestListId",
              selection: _vm.popupParam.type,
              editable: _vm.editable,
            },
            on: { rowDblclick: _vm.rowDblclick },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-btn", {
                      attrs: { label: "선택", icon: "check" },
                      on: { btnClicked: _vm.select },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }