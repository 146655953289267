<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="보호구 목록"
        tableId="grid"
        :columns="grid.columns"
        :data="grid.data"
        :isExcelDown="false"
        :isExpan="false"
        :isFullScreen="false"
        :columnSetting="false"
        gridHeight="500px"
        rowKey="requestListId"
        :selection="popupParam.type"
        :editable="editable"
        @rowDblclick="rowDblclick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="선택" icon="check" @btnClicked="select" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-request-gear-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          requestGiveInfoId: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'safetyTypeName',
            field: 'safetyTypeName',
            label: '보호구종류',
            style: 'width:120px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'safetyGearName',
            field: 'safetyGearName',
            label: '보호구명',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'requestCount',
            field: 'requestCount',
            label: '신청수량',
            style: 'width:80px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            style: 'width:350px',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        useFlag: 'Y',
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.sas.request.get.url;
      this.getList();
    },
    getList() {
      if (this.popupParam.requestGiveInfoId) {
        this.$http.url = this.$format(this.listUrl, this.popupParam.requestGiveInfoId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data.detailList;
        },);
      } 
    },  
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '보호구를 지정하세요.', // 보호구를 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
